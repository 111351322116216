import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const LaserInfoTemplate = ({
  title,
  description,
  image,
  content,
  contentTitle,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <section className="subPage">
      <div
        className="image"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp
              ? image.childImageSharp.fluid.src
              : image.featuredimage
          })`,
        }}
      >
        <div className="titles">
          <h1>{title}</h1>
          <h2>{description}</h2>
        </div>
      </div>
      <div className="container">
        {contentTitle}
        <PostContent content={content} />
      </div>
    </section>
  );
};

LaserInfoTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  contentComponent: PropTypes.func,
};

const LaserInfo = ({ pageContext: { locale }, ...props }) => {
  const { node: data } = props.data.LaserInfoData.edges[0];
  return (
    <Layout
      locale={locale}
      title={data.frontmatter.title}
      description={data.frontmatter.description}
    >
      <Helmet>
        <title>{data.frontmatter.title}</title>
        <meta name="description" content={`${data.frontmatter.description}`} />
      </Helmet>
      <LaserInfoTemplate
        contentComponent={HTMLContent}
        image={data.frontmatter.image}
        title={data.frontmatter.title}
        description={data.frontmatter.description}
        contentTitle={data.frontmatter.contentTitle}
        content={data.html}
      />
    </Layout>
  );
};

LaserInfo.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export default LaserInfo;

export const laserInfoQuery = graphql`
  query laserInfoQuery($locale: String) {
    LaserInfoData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "laser-info" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            locale
            title
            description
            contentTitle
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
